import axios from 'axios'
/**
 * Wrapper for axios, to globally use in the app (i.e manual GETs and POST, PATCH, etc.)
 * for EXTERNAL URLs (example: friendlyCaptcha api)
 * @param url
 * @param opts
 * @returns the new alias $externalApi, configured with the external base url and necessary headers
 */
export const $externalApi: typeof axios = (url: String, opts?) => {
  const axiosConfig = {
    method: opts.method,
    url,
    baseURL: '/api/external/',
    data: opts.data,
    headers: {
      ...opts.headers,
    },
  }
  return axios(axiosConfig)
}
